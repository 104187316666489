import { useState } from "react"

export function MenuSetting() {


    const [testVal,setTestVal]=new useState(0);

    function click(){
        setTestVal(testVal+1)
    }


    return (
        <section>

            <h1 onClick={click}>메뉴 관리</h1>

            {testVal}            


        </section>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Page/Main'
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import MainPage from './Page/Main';
import JoinPage from './Page/Join';

//호스트페이지 S
import {HostContextProvider} from './context/HostContext'
import Host from './Page/Host';
import './Page/HostMenu/TableManagement'
import { TableManagement } from './Page/HostMenu/TableManagement';
import { OrderManagement } from './Page/HostMenu/OrderManagement';
import { MenuManagement } from './Page/HostMenu/MenuManagement';
import { MenuAdd } from './Page/HostMenu/MenuManagementTab/MenuAdd'
import { MenuSetting } from './Page/HostMenu/MenuManagementTab/MenuSetting'
import { KategorieSetting } from './Page/HostMenu/MenuManagementTab/KategorieSetting'
import { Setting } from './Page/HostMenu/Setting'
//호스트페이지 E

//클라이언트 페이지 S
import  './Page/ClientMenu/Client'
import {ClientPage} from './Page/ClientMenu/Client'
import { OrderPage } from './Page/ClientMenu/Order';

//클라이언트 페이지 E

//
import {PaymentPage} from './Page/other/PaymentPage'
import {TestPage} from './Page/test'
//



const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(

  <BrowserRouter>


    <Routes>

      <Route path='/test' element={<TestPage />} />


      <Route path='/index/' element={<MainPage />} />
      <Route path='/' element={<MainPage />} />

      <Route path='/join' element={<JoinPage />} />                             
      

      <Route path='/host/' element={<HostContextProvider><Host /></HostContextProvider>}>                                 

          <Route path='' element={<TableManagement />} />                             
          <Route path='OrderManagement' element={<OrderManagement />} />              
          <Route path='TableManagement' element={<TableManagement />} />              

          <Route path='MenuManagement/' element={<MenuManagement />}>                 

            <Route path='' element={<KategorieSetting />} />                                   
            <Route path='menuAdd' element={<MenuAdd />} />                               
            {/* <Route path='menuSetting' element={<MenuSetting />} />                        */}
            <Route path='kategorieSetting' element={<KategorieSetting />} />              

          </Route>                                                                    

        <Route path='Setting' element={<Setting />}  />                         


      </Route>                                                                  

      <Route path='/client/'   >

        <Route path='' element={<ClientPage><OrderPage /></ClientPage>} />

        <Route path=':tablePath/' >
          <Route path='' element={<ClientPage><OrderPage /></ClientPage>} />
  
        </Route>

      </Route>

      <Route path='/payment/:isSuccess' element={<ClientPage><PaymentPage /></ClientPage>}   >


      </Route>

    </Routes>
  </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

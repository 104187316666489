// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#join_joinArea__sZv9K{

    width: 40%;

    background-color: white;
    border-radius: 5px;
    padding: 50px;
}`, "",{"version":3,"sources":["webpack://./src/static/css/join.module.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;;IAEV,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":["#joinArea{\n\n    width: 40%;\n\n    background-color: white;\n    border-radius: 5px;\n    padding: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"joinArea": `join_joinArea__sZv9K`
};
export default ___CSS_LOADER_EXPORT___;

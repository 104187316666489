
export function Setting(){


    return(
        <>
        
         <section>

            <h1 >설정 페이지</h1>

                        


        </section>
        
        </>
    )
}
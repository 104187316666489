import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApplicationContext } from '../../context/ApplicationContext';
import { ClientContext } from '../ClientMenu/Client';


export function PaymentPage(props) {

    const applicationContext=useContext(ApplicationContext)

    const clientContext = useContext(ClientContext)

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();



    function goHostPage(){
        navigate(`/host`)

    }
    
    
    
    useEffect(() => {
        
        
        const amount = searchParams.get('amount');
        const orderId = searchParams.get('orderId');
        const paymentKey = searchParams.get('paymentKey');
        let tableNum = orderId.split('_')[2]


        // console.log(orderId)
        // console.log(amount)
        // console.log(paymentKey)


        axios({
            method: 'POST',
            url: 'https://api.tosspayments.com/v1/payments/confirm',
            headers: {
                Authorization: 'Basic dGVzdF9za19PNkJZcTdHV1BWdk5sNW9kRFFtVk5FNXZibzFkOg==',
                'Content-Type': 'application/json'
            },
            data: {
                paymentKey: paymentKey,
                amount: amount,
                orderId: orderId
            }
        })
        .then(function(response){
            console.log(response)

            let formData=new FormData()
            formData.append('tableNum',tableNum)

            axios.post(`${applicationContext.kioskServer}/host/payment/sc`,formData)
            .then(function(response){

                let responseData=response.data

                if(responseData.isSuccess){

                    console.log(responseData)
                    goHostPage()
                }else{
                    alert('실패')
                }


            })
            .catch(function(err){
                alert(err)
            })

        })
        .catch(function(err){
            alert(err)
        })

        



    }, [])



    return (
        <>


            <h1 onClick={() => {
                // navigate('/home');


            }} >결제 페이지</h1>


        </>

    )


}
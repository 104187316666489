import { Layout } from '../Component/layout/MainLayout/MainLayout'
import React, { useContext, useState } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import axios from 'axios';

import joinStyle from '../static/css/join.module.css'
import { ApplicationContext } from '../context/ApplicationContext';
import { Navigate } from 'react-router-dom';


const Postcode = () => {

    const [address, setAddress] = new useState({

    });

    const open = useDaumPostcodePopup('https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');

    const handleComplete = (data) => {
        console.log(data)
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        setAddress(prev => data)
    };

    const handleClick = () => {
        open({ onComplete: handleComplete });
    };

    return (
        <>
            <label onClick={handleClick} style={{ marginBottom: '0px' }} for="address2" className="form-label">가맹점 위치 <span className="text-muted"></span></label>
            <div style={{ marginTop: '0px' }} className="col-md-9"  >
                <label style={{ fontSize: '10px' }} for="country" className="form-label">도로명 주소</label>
                <input name='roadAddress' type="text" className="form-control" id="zip" readOnly placeholder="" required="" value={address.roadAddress} onClick={handleClick} />

                <div className="roadAddress invalid-feedback">
                    Please select a valid country.
                </div>
            </div>



            <div style={{ marginTop: '0px' }} className="col-md-3">
                <label style={{ fontSize: '10px' }} for="zip" className="form-label lb-lg">우편번호</label>
                <input name='zipCode' type="text" className="form-control" id="zip" readOnly placeholder="" required="" value={address.zonecode} onClick={handleClick} />
                <div className="invalid-feedback">
                    Zip code required.
                </div>
            </div>

            <div className="col-sm-12">
                <label style={{ fontSize: '10px' }} for="detailAddress" className="form-label">상세주소</label>
                <input name='detailAddress' type="text" className="form-control" id="detailAddress" placeholder="" required="" />
                <div className="detailAddress invalid-feedback">
                    Valid first name is required.
                </div>
            </div>

        </>
        // <button type='button' onClick={handleClick}>
        //     Open
        // </button>
    );
};




export default function JoinPage() {

    const applicationContext = useContext(ApplicationContext)
    
    const joinPs = (e) => {

        let formData = e.target.parentElement

        
    
        axios({
            method: 'post',
            url: `${applicationContext.kioskServer}/join`,
            responseType: 'json',
            data: formData,
            withCredentials:true
          }).then(function(response){

            // 오류 필드 초기화 S //
            let ErrorEl = formData.getElementsByClassName('invalid-feedback')

            for(let el of ErrorEl){
                el.style="display:none"
            }
            // 오류 필드 초기화 E //

            let responseData=response.data

            if(responseData.isSuccess){
                console.log('성공')
                console.log(responseData)
                window.location.href=`/host`


            }else{
                console.log('실패')
                console.log(responseData)

                for(let field of responseData.data){

                    let selectEl = document.getElementsByClassName(`${field.field} invalid-feedback`)[0];
                   
                    
                    selectEl.innerHTML=field.defaultMessage

                    selectEl.style='display:block;'

                    
                }

            }



          }).catch(function(error){
            console.log(error)

          })
    
    }

    const Content = {
        mainContent:

            <div id={joinStyle.joinArea} >

                <h1 >회원가입</h1>
                <form className="needs-validation" novalidate="">
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <label for="firstName" className="form-label">회원명</label>
                            <input name='name' type="text" className="form-control" id="firstName" placeholder="" required="" />
                            <div className="name invalid-feedback">
                                Valid first name is required.
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <label for="storeName" className="form-label">가게명</label>
                            <input name='storeName' type="text" className="form-control" id="storeName" placeholder="" required="" />
                            <div className="storeName invalid-feedback">
                                Valid last name is required.
                            </div>
                        </div>



                        <div className="col-12">
                            <label for="email" className="form-label">Email <span className="text-muted">(ID)</span></label>
                            <input name='id' type='email' className="form-control" id="email"  />
                            <div className="id invalid-feedback">
                                Please enter a valid email address for shipping updates.
                            </div>
                        </div>

                        <div className="col-12">
                            <label for="password" className="form-label">Password</label>
                            <input name='password' type="password" className="form-control" id="Password" />
                            <div className="password invalid-feedback">
                                Please enter your password.
                            </div>
                        </div>

                        <div className="col-12">
                            <label for="passwordC" className="form-label">Password Check</label>
                            <input name='passwordC' type="password" className="form-control" id="PasswordC" />
                            <div className="passwordC invalid-feedback">
                                Please enter your password.
                            </div>
                        </div>

                        <Postcode />




                    </div>

                    <hr className="my-4" />

                    <button onClick={joinPs} className="w-100 btn btn-primary btn-lg" type="button">회원가입</button>
                </form>


            </div>
    }

    return (
        <Layout mainContent={Content.mainContent} />
    )


}


import React from 'react';
import { Link, Outlet } from 'react-router-dom'
import menuManagementStyle from '../../static/css/HostMenu/menuManagement.module.css'

export function MenuManagement() {


    return (
        <div className={menuManagementStyle.MenuManagementNav} >



            <div className={menuManagementStyle.MenuManagementNavList} >
                {/* <section>
                    <Link to='/host/MenuManagement/menuSetting' >메뉴 관리</Link>
                </section> */}
                <section>
                    <Link to='/host/MenuManagement/kategorieSetting' >메뉴 관리</Link>
                </section>
                <section>
                    <Link to='/host/MenuManagement/menuAdd' >메뉴 추가</Link>
                </section>
            </div>

            <Outlet />
        </div>
    )

}

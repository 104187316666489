import KategorieSettingStyle from '../../../static/css/HostMenu/MenuManagementTab/KategorieSetting.module.css'
import { ApplicationContext } from '../../../context/ApplicationContext'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'



export function KategorieSetting() {

    //정적 변수 불러오기 (백엔드 서버 주소)
    const applicationContext = useContext(ApplicationContext)

    //카테고리 데이터 
    const [kategorieList, setKategorieList] = new useState([])

    const [menuList, setMenuList] = new useState([])


    // 페이지 로딩 또는 카테고리 수정 시 서버에서 재로딩
    useEffect(function () {

        //카테고리 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/kategorieSetting/gets`)
            .then(function (response) { //카테고리 데이터 처리
                const result = response.data
                //정상 응답시 처리
                if (result.isSuccess) {
                    console.log('카태고리 데이터',result.data)
                    setKategorieList([...result.data])

                } else {

                }

            }).catch(function (err) {
                alert(err)
            })

        //메뉴 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/gets`)
            .then(function (response) {

                const result = response.data

                if (result.isSuccess) {
                    console.log('메뉴 데이터',result.data)
                    setMenuList([...result.data])
                } else {

                }


            })
            .catch(function (err) {

            })


    },[])




    // 카테고리 추가 이벤트 처리 (카테고리 + 버튼 클릭 시)
    const addKategorie = (e) => {

        let parentEl = e.currentTarget.parentElement;

        let inputEl = parentEl.querySelector('input')
        console.log(inputEl)

        const formData = new FormData()

        formData.append('addKategorie', inputEl.value)

        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/kategorieSetting/add`, formData)
            .then(function (response) {
                let result = response.data

                if (result.isSuccess) {
                    setKategorieList([

                        ...kategorieList,
                        result.data

                    ])


                    let test = document.getElementById('addArea')
                    test.classList.toggle('dn')
                    console.log(test)
                } else {

                }


            })
            .catch(function (err) {
                console.log(err)
            })



    }


    //카테고리 클릭 이벤트
    const findByKategorie = (e) => {

        const selectKategorie = e.currentTarget.dataset.kategorie

        console.log(selectKategorie)

        const formData = new FormData()

        if(selectKategorie){
            formData.append('kategorie',selectKategorie)

        }


        //메뉴 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/gets`,formData)
            .then(function (response) {

                const result = response.data

                if (result.isSuccess) {
                    setMenuList([...result.data])
                } else {

                }


            })
            .catch(function (err) {

            })


    }

    const deleteMenu=(e)=>{

        const menuNum=e.currentTarget.dataset.num


        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/delete`,{num:menuNum})
            .then(function(response){
                console.log(response.data)
            })
            .catch(function(err){
                console.log(err)
            })

    }


    return (
        <>
            <div id={KategorieSettingStyle.kategoryController} >
                <section id={KategorieSettingStyle.kategoryList} >

                    <ul id={KategorieSettingStyle.kategoryUl} >
                        <li onClick={(e)=>{findByKategorie(e)}} >
                            <p>카테고리</p>
                        </li>
                        <li onClick={(e)=>{findByKategorie(e)}} >
                            <p>모두</p>
                        </li>

                        {
                            kategorieList.map((data, index) => {
                                return (

                                    <li data-kategorie={data.num} onClick={(e) => {
                                        findByKategorie(e)
                                    }} key={index} >
                                        <p>{data.name}</p>
                                    </li>


                                )
                            })
                        }

                        <li className='dn' id='addArea'  >
                            <p >
                                <input></input>
                                <i onClick={(e) => addKategorie(e)} style={{ marginLeft: '5px' }} className='xi-check'></i>
                            </p>
                        </li>


                        <li id='addKategorieli' onClick={(e) => {

                            let test = document.getElementById('addArea')
                            test.classList.toggle('dn')
                            console.log(test)

                        }}  >
                            <p >
                                <i className='xi-plus'></i>
                            </p>
                        </li>

                    </ul>

                </section>


                <section id={KategorieSettingStyle.showKategorie} >

                    <div id={KategorieSettingStyle.menuArea} >

                        {menuList.map((t, index) => {

                            console.log(t)

                            return (

                                <dl key={index} >
                                    <dt>
                                        <img alt='' src={t.photo} ></img>
                                    </dt>

                                    <dd>
                                        <p>
                                            {t.name}
                                        </p>
                                        <p>
                                            {t.kategorieDto.name}
                                        </p>
                                        <p>
                                            {t.price}
                                        </p>

                                    </dd>

                                    <div className={KategorieSettingStyle.btnGroup} >
                                        <i onClick={deleteMenu}  data-num={t.num} className='xi-trash-o' ></i>
                                    </div>
                                    

                                </dl>

                            )

                        })}

                    </div>

                </section>



            </div>
        </>

    )
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header{
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: right;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
header > i{
    font-size: 30px;
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Component/layout/MainLayout/header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;;AAExB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":["header{\n    position: fixed;\n    display: flex;\n    width: 100%;\n    justify-content: right;\n    color: white;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    \n}\nheader > i{\n    font-size: 30px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

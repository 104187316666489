import styles from '../static/css/login.module.css'
import { Layout } from '../Component/layout/MainLayout/MainLayout'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect } from 'react'
import { ApplicationContext } from '../context/ApplicationContext'
import { HostContext } from '../context/HostContext'
import { ClientContext } from './ClientMenu/Client'

export function LoginPage() {

    const applicationContext=useContext(ApplicationContext)

    const hostContext =useContext(HostContext)

    const clientContext =useContext(ClientContext)

    useEffect(()=>{

        if(clientContext){

            if(clientContext.loginUser==null){
                axios({
                    method: 'post',
                    url: `${applicationContext.kioskServer}/login/loginCk`,
                    responseType: 'json',
                    withCredentials:true
                })
                .then(function(response){
                    const result = response.data

                    if(result.data!=null){
                        clientContext.setLoginUser(result.data)
                    }

                })
                .catch(function(err){

                })
                
            }
        }

        if(hostContext){
            if(hostContext.loginUser==null){
                axios({
                    method: 'post',
                    url: `${applicationContext.kioskServer}/login/loginCk`,
                    responseType: 'json',
                    withCredentials:true
                })
                .then(function(response){
                    const result = response.data

                    if(result.data!=null){
                        hostContext.setLoginUser(result.data)
                    }

                })
                .catch(function(err){

                })
                
            }
        }
            
    },[])
    
    const loginPs =(e)=>{
        
        const formData=e.target.parentElement.parentElement
        
        axios({
            method: 'post',
            url: `${applicationContext.kioskServer}/login`,
            responseType: 'json',
            data: formData,
            withCredentials:true
          }).then(function(response){
              
            const result=response.data

            if(result.isSuccess){
                // window.location.href=window.location.origin+'/host'

                if(hostContext){
                    hostContext.setLoginUser(result.data)


                }
                if(clientContext){
                    clientContext.setLoginUser(result.data)
                }

            }else{
                document.getElementById('validationErr').style.display='block'
            }
            

          }).catch(function(err){
            alert("담당자에게 문의해주세요"+err)
          })
        
    }


    const Content = {

        MainContent:
            <>
                <section id={styles.loginArea} >

                    <h2>로그인</h2>
                    <div id="validationErr"  className="invalid-feedback">
                        아이디 또는 비밀번호를 확인해주세요
                    </div>
                    <form>

                        <div className="form-floating mb-3">
                            <input name='id' type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating">
                            <input name='password' type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>  

                        <div className="form-floating">
                            <button onClick={(e)=>{loginPs(e);}}  className="w-100 btn btn-primary btn-lg" type="button">로그인</button>

                        </div>

                        <div className="form-floating">
                            <Link to='/join' >

                                <button  className="w-100 btn btn-secondary btn-lg" type="button">회원가입</button>

                            </Link>
                        </div>


                    </form>
                </section>
            </>

    }

    return (
        <Layout mainContent={Content.MainContent} />
    )

}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuAdd_flexR__wn7Xp{
    display: flex;
}



.MenuAdd_flexC__lbR3j section:last-child{
    flex-grow: 1;

}

.MenuAdd_flexR__wn7Xp > section{
    flex-direction: column;
    align-items: center;
}

.MenuAdd_flexR__wn7Xp > section >div:first-child{
    width: 100%;
    flex-direction: row !important;
}

.MenuAdd_flexR__wn7Xp > section:last-child{
    max-height: 690px;
    margin-left: 10px;
    max-width: 400px;

    
}

.MenuAdd_flexR__wn7Xp > section:last-child >form{
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    padding: 30px;
}

.MenuAdd_flexR__wn7Xp > section:last-child >form >dl{
    margin: 5px;
    margin-top: 20px;
}

.MenuAdd_flexR__wn7Xp > section:last-child >form >dl>dt{
}


.MenuAdd_flexR__wn7Xp > section:last-child >form >dl>dd{
    margin-bottom: 10px;
    padding: 5px;
    
}
.MenuAdd_flexR__wn7Xp > section:last-child >form >dl>dd >input,textarea{
    margin-bottom: 5px;
}

.MenuAdd_flexR__wn7Xp > section:first-child{
    margin-right: 10px;
    max-width:600px;
    

}

.MenuAdd_flexR__wn7Xp > section:first-child #MenuAdd_previewPicture__biN6h img{
    width: 250px;
    height: 250px;
    background-color: rgb(231, 231, 231);
    padding: 10px;

}

.MenuAdd_flexR__wn7Xp > section:first-child #MenuAdd_previewName__zkZxl{
    font-size: 30px;

}
.MenuAdd_flexR__wn7Xp > section:first-child #MenuAdd_previewPrice__oMPw6{
    font-size: 20px;
}

.MenuAdd_flexR__wn7Xp > section:first-child #MenuAdd_previewComment__pyGIv{


}

`, "",{"version":3,"sources":["webpack://./src/static/css/HostMenu/MenuManagementTab/MenuAdd.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;;AAIA;IACI,YAAY;;AAEhB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;;;AAGpB;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;AACA;;;AAGA;IACI,mBAAmB;IACnB,YAAY;;AAEhB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;;;AAGnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,aAAa;;AAEjB;;AAEA;IACI,eAAe;;AAEnB;AACA;IACI,eAAe;AACnB;;AAEA;;;AAGA","sourcesContent":[".flexR{\n    display: flex;\n}\n\n\n\n.flexC section:last-child{\n    flex-grow: 1;\n\n}\n\n.flexR > section{\n    flex-direction: column;\n    align-items: center;\n}\n\n.flexR > section >div:first-child{\n    width: 100%;\n    flex-direction: row !important;\n}\n\n.flexR > section:last-child{\n    max-height: 690px;\n    margin-left: 10px;\n    max-width: 400px;\n\n    \n}\n\n.flexR > section:last-child >form{\n    background-color: rgb(240, 240, 240);\n    border-radius: 5px;\n    padding: 30px;\n}\n\n.flexR > section:last-child >form >dl{\n    margin: 5px;\n    margin-top: 20px;\n}\n\n.flexR > section:last-child >form >dl>dt{\n}\n\n\n.flexR > section:last-child >form >dl>dd{\n    margin-bottom: 10px;\n    padding: 5px;\n    \n}\n.flexR > section:last-child >form >dl>dd >input,textarea{\n    margin-bottom: 5px;\n}\n\n.flexR > section:first-child{\n    margin-right: 10px;\n    max-width:600px;\n    \n\n}\n\n.flexR > section:first-child #previewPicture img{\n    width: 250px;\n    height: 250px;\n    background-color: rgb(231, 231, 231);\n    padding: 10px;\n\n}\n\n.flexR > section:first-child #previewName{\n    font-size: 30px;\n\n}\n.flexR > section:first-child #previewPrice{\n    font-size: 20px;\n}\n\n.flexR > section:first-child #previewComment{\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexR": `MenuAdd_flexR__wn7Xp`,
	"flexC": `MenuAdd_flexC__lbR3j`,
	"previewPicture": `MenuAdd_previewPicture__biN6h`,
	"previewName": `MenuAdd_previewName__zkZxl`,
	"previewPrice": `MenuAdd_previewPrice__oMPw6`,
	"previewComment": `MenuAdd_previewComment__pyGIv`
};
export default ___CSS_LOADER_EXPORT___;

import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";
import { HostContext } from "../../context/HostContext";
import Styles from '../../static/css/HostMenu/tableView.module.css'
import { Popup } from '../../Component/layout/Popup'
import axios from "axios";

export function TableManagement() {

    const applicationContext = useContext(ApplicationContext)

    const hostContext = useContext(HostContext)


    let tableState = hostContext.table

    // let setTable = hostContext.setTable

    const [tableOrderPopup, setTableOrderPopup] = useState('dn')

    const [selectTableInfo, setSelectTableInfo] = useState({
        orderData: {
            menus: []
        }
    })

    const clientKey = 'test_ck_ADpexMgkW36PDm5OyXMrGbR5ozO0'




    useEffect(() => {



    }, [tableState,selectTableInfo,hostContext.table])


    const paymentPs = (e) => {
        let target = e.currentTarget

        let tableNum = target.dataset.tablenum

        let tableData = hostContext.table[tableNum]

        let totalPrice = 0

        let orderName = tableData[0].name

        if (tableData.lenght > 1) {
            orderName += `외 ${tableData.lenght - 1} 항목`
        }


        for (let data of tableData) {

            totalPrice += data.price * data.count

        }



        loadTossPayments(clientKey).then(tossPayments => {

            tossPayments.requestPayment('카드', {
                // 결제 정보
                amount: totalPrice,
                orderId: `Kiosk_${hostContext.loginUser.num}_${tableNum}_${new Date().getTime()}`,
                orderName: orderName,
                customerName: `${hostContext.loginUser.storeName}`,
                successUrl: `${applicationContext.kioskFront}/payment/sc`,
                failUrl: `${applicationContext.kioskFront}/payment/fail`,
                flowMode: 'DIRECT',
                easyPay: '토스페이'
            })
                .catch(function (error) {
                    if (error.code === 'USER_CANCEL') {
                        // 결제 고객이 결제창을 닫았을 때 에러 처리
                    } else if (error.code === 'INVALID_CARD_COMPANY') {
                        // 유효하지 않은 카드 코드에 대한 에러 처리
                    }
                }).finally(function () {
                })
        })


    }

    const tableOrderDetailPs = (e) => {
        let target = e.currentTarget
        let tableNum = target.dataset.tablenum
        console.log(tableNum)

        let formData = new FormData()
        formData.append('tableNum', tableNum)

        setTableOrderPopup('')
        axios.post(`${applicationContext.kioskServer}/host/TableManagement/getTableData`, formData)
            .then(function (response) {
                let responseData = response.data

                if (responseData.isSuccess) {
                    console.log(responseData.data)
                    setSelectTableInfo(responseData.data[0])

                } else {
                    alert(responseData.message)
                }

            })
            .catch(function (err) {
                alert(err)
            })

    }

    const updateTableState = (e)=>{
        

        let formData=new FormData()
        formData.append('tableState',JSON.stringify(selectTableInfo))

        
        

        axios.post(`${applicationContext.kioskServer}/host/TableManagement/tableEdit`,formData)
            .then(function(response){
                let responseData = response.data

                if(responseData.isSuccess){
                    
                    let menus = responseData.data.orderData.menus

                    for(let menu of menus){
                        menu.name=menu.menu.name
                    }

                    let newTable =[...hostContext.table]
                    
                    newTable[selectTableInfo.tableNum]=menus

                    hostContext.setTable(newTable)
                    

                    setTableOrderPopup('dn')

                }else{
                    alert('관리자에게 문의해주세요')
                }
                

            })
            .catch(function(err){
                alert(err)
            })

        

    }





    return (
        <section className={Styles.tableViewArea} >

            <h1 onClick={() => {

                console.log(hostContext.table)

            }} >테이블 관리</h1>

            <div id={Styles.tableView} >

                {
                    hostContext.table.map((t, index) => {

                        if (typeof t != 'object') {
                            return ('')
                        }

                        return (
                            <dl key={index} >
                                <dt>
                                    <span>
                                        {index}
                                    </span>
                                    <span>
                                        <i className="xi-won" data-tablenum={index}

                                            onClick={(e) => {
                                                paymentPs(e)
                                            }}

                                        ></i>
                                        <i className="xi-list" data-tablenum={index}

                                            onClick={(e) => {
                                                tableOrderDetailPs(e)
                                            }}


                                        ></i>
                                    </span>

                                </dt>
                                <dd>
                                    <ul>

                                        {


                                            t.map((menu, index) => {

                                                return (
                                                    <li key={index}>

                                                        {menu.name} x {menu.count}

                                                    </li>

                                                )

                                            })


                                        }

                                    </ul>
                                </dd>
                            </dl>

                        )

                    })
                }
            </div>
            <Popup id='tableOrderPopup' className={tableOrderPopup} >
                <div id={Styles.tableOrderArea} >
                    <h2>주문내역</h2>
                    <ul id={Styles.tableOrderList} >
                        {
                            selectTableInfo.orderData.menus.map((menu, index) => {

                                return (
                                    <li key={index} >
                                        <span>
                                            {menu.menu.name}
                                        </span>

                                        <span>
                                            <button onClick={(e) => {
                                                menu.count += 1
                                                setSelectTableInfo({...selectTableInfo})

                                            }} >
                                                <i className="xi-plus-min"  ></i>
                                            </button>

                                            <input onChange={(e) => {
                                            }}

                                                value={menu.count} ></input>

                                            <button onClick={(e) => {
                                                menu.count -= 1
                                                setSelectTableInfo({...selectTableInfo})

                                            }} >
                                                <i className="xi-minus-min" ></i>
                                            </button>
                                        </span>
                                    </li>

                                )
                            })
                        }
                        {/* 
                        <li>
                            <span>
                                항목 1
                            </span>

                            <span>
                                <button>
                                    <i className="xi-plus-min" ></i>
                                </button>

                                <input></input>

                                <button>
                                    <i className="xi-minus-min" ></i>
                                </button>
                            </span>
                        </li> */}

                    </ul>
                    <div className={Styles.btnGroup} >
                        <button onClick={()=>{
                            updateTableState()
                        }} >확인</button>
                        <button onClick={() => {
                            setTableOrderPopup('dn')
                        }} >취소</button>

                    </div>

                </div>
            </Popup>
        </section>
    )

}

import { Layout } from '../Component/layout/MainLayout/MainLayout'
import mainStyle from '../static/css/main.module.css'
import {Link} from 'react-router-dom'

export default function MainPage() {


    const Content = {

        mainContent:
            <>
                <div id={mainStyle.mainMenu} >

                    <Link to='/host' >
                        <i className='xi-shop' ></i>
                        <span>호스트</span>
                    </Link>
                    <Link to='/client/' >
                        <i className='xi-document' ></i>
                        <span>클라이언트</span>

                    </Link>
                    <Link to='/join' >
                        <i className='xi-user-plus-o' ></i>
                        <span>회원가입</span>

                    </Link>

                </div>

            </>



    }

    return (
        <Layout mainContent={Content.mainContent} />

    )

}



import React, { useState, createContext, useContext, useEffect } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";




export const ClientContext = createContext();


export function ClientPage(props) {

    const applicationContext = useContext(ApplicationContext)

    const initialItems = null;

    const [webSocket,setWebSocket] = useState(initialItems);

    const [loginUser,setLoginUser]=useState(null)

    const [tableNum,setTableNum] = useState(0);

    const [orderCart,setOrderCart] =useState([]);


    useEffect(()=>{

    },[tableNum,orderCart])
    

    function getWebSocket(){

        if(webSocket){    
            console.log('기존 소켓을 리턴')
            return webSocket
        
        }else{
            console.log('새로운 소켓을 리턴')

            const createSocket =new WebSocket(`wss://${applicationContext.kioskServerIp}/chatt`);


            setWebSocket(createSocket)

            return webSocket
        }

    }

    return (
        <ClientContext.Provider value={{getWebSocket,loginUser,setLoginUser,tableNum,setTableNum,orderCart,setOrderCart}}>
            {props.children}
        </ClientContext.Provider>
    )
}
 
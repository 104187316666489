import { createContext } from "react";

export const ApplicationContext=createContext({
    kioskServer:'https://kioskapi.bmserver.org',
    kioskServerIp:'kioskapi.bmserver.org',
    kioskFront:'https://kioskapi.bmserver.org',
    // kioskServer:'http://localhost:3001',
    // kioskServerIp:'localhost:3001',
    // kioskFront:'http://localhost:3000'

});


import { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Layout } from "../Component/layout/MainLayout/MainLayout";
import hostStyle from "../static/css/host.module.css";
import { HostContext } from "../context/HostContext";
import { LoginPage } from "../Page/Login";
import axios from "axios";
import { ApplicationContext } from "../context/ApplicationContext";
import { Popup } from "../Component/layout/Popup";

axios.defaults.withCredentials = true;

export default function HostPage() {
  const hostContext = useContext(HostContext);

  const applicationContext = useContext(ApplicationContext);

  const [popup, setPopup] = useState("");

  const webSocketOnmessage = (evt) => {
    let response = JSON.parse(evt.data);

    console.log(response);

    let responseAction = response.action;

    switch (responseAction) {
      case "ORDER":
        addTableData(response);
        setTimeout(() => {
          setOrderHistory();
        }, 2000);

        break;
    
        default:
            
    }

    hostContext.webSocket.onmessage = webSocketOnmessage;
  };

  const addTableData = (response) => {
    let responseData = response.data;

    let tableNum = responseData.tableNum;

    let orderMenu = responseData.menuData;

    // let requestUser = response.requestUser;

    if (hostContext.table[tableNum] === undefined) {
      hostContext.table[tableNum] = orderMenu;
    } else {
      hostContext.table[tableNum].push(...orderMenu);
    }

    hostContext.setTable([...hostContext.table]);
  };

  function setTableData() {
    console.log("테이블 데이터를 불러옵니다");

    axios
      .post(
        `${applicationContext.kioskServer}/host/TableManagement/getTableData`
      )
      .then(function (response) {
        console.log(response);

        let responseData = response.data;

        if (responseData.isSuccess) {
          for (let tableData of responseData.data) {
            let menuArray = [];

            for (let orderMenu of tableData.orderData.menus) {
              if (orderMenu.menu == null) {
                menuArray.push({
                  // comment:orderMenu.menu.comment,
                  count: orderMenu.count,
                  // kategorieDto:orderMenu.menu.kategorieDto,
                  name: "삭제된 메뉴",
                  // num:orderMenu.menu.num,
                  // photo:orderMenu.menu.photo,
                  // price:orderMenu.menu.price,
                  // user:orderMenu.menu.user
                });
              } else {
                menuArray.push({
                  comment: orderMenu.menu.comment,
                  count: orderMenu.count,
                  kategorieDto: orderMenu.menu.kategorieDto,
                  name: orderMenu.menu.name,
                  num: orderMenu.menu.num,
                  photo: orderMenu.menu.photo,
                  price: orderMenu.menu.price,
                  user: orderMenu.menu.user,
                });
              }
            }

            hostContext.table[tableData.tableNum] = menuArray;
          }

          hostContext.setTable([...hostContext.table]);
        } else {
          console.log(response);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  function setOrderHistory() {
    console.log("주문 내역 불러오기");

    let formData = new FormData();

    formData.append("startDay", 0);
    formData.append("offsetDay", 1);

    axios
      .post(`${applicationContext.kioskServer}/host/order/history`, formData)
      .then(function (response) {
        console.log(response.data);

        let responseData = response.data;

        if (responseData.isSuccess) {
          console.log(responseData.data);
          hostContext.setOrderHistory([...responseData.data]);
        } else {
        }
      })
      .catch(function (err) {
        alert(err);
      });
  }

  useEffect(() => {
    //로그아웃 시
    if (hostContext.loginUser == null) {
      axios({
        method: "post",
        url: `${applicationContext.kioskServer}/login/loginCk`,
        responseType: "json",
        withCredentials: true,
      })
        .then(function (response) {
          const result = response.data;
          if (result.data != null) {
            hostContext.setLoginUser(result.data);
          }
        })
        .catch(function (err) {});
      // 로그인 시
    } else {
    }

    const ws = new WebSocket(`wss://${applicationContext.kioskServerIp}/chatt`);

    hostContext.setWebSocket(ws);

    console.log(hostContext.webSocket);
  },[]);

  if (hostContext.loginUser === null) {
    return <LoginPage />;
  } else {
  }

  const HostAccess = () => {
    hostContext.webSocket.send(
      JSON.stringify({
        action: "HOSTACCESS",
        requestUser: hostContext.loginUser,
        data: null,
      })
    );

    hostContext.webSocket.onmessage = null;

    hostContext.webSocket.onmessage = webSocketOnmessage;
  };

  const Content = {
    mainContent: (
      <div id={hostStyle.mainContent}>
        <nav onClick={() => {}}>
          <ul>
            <li>
              <Link to="/host/TableManagement">
                <p>테이블 보기</p>
              </Link>
            </li>

            <li>
              <Link to="/host/OrderManagement">
                <p>주문 관리</p>
              </Link>
            </li>

            <li>
              <Link to="/host/MenuManagement">
                <p>메뉴 관리</p>
              </Link>
            </li>

            <li>
              <Link to="/host/Setting">
                <p>설정</p>
              </Link>
            </li>
          </ul>
        </nav>
        <Popup id="connectPopup" className={popup}>
          <button
            className={hostStyle.connectBtn}
            onClick={() => {
              HostAccess();
              setTableData();
              setOrderHistory();
              setPopup("dn");
            }}
          >
            접속하기
          </button>
        </Popup>
        <Outlet />
      </div>
    ),
  };

  return <Layout mainContent={Content.mainContent} />;
}

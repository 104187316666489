// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuManagement_MenuManagementNav__F6Ybj{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.menuManagement_flexR__2pEGi{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}


.menuManagement_MenuManagementNav__F6Ybj .menuManagement_MenuManagementNavList__v2fF4{
    height: 40px;
    margin-bottom: 10px;
    display: flex;
    width: 450px;
    
    
}
.menuManagement_MenuManagementNav__F6Ybj .menuManagement_MenuManagementNavList__v2fF4 > section{
    margin-right: 15px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    
    
}
.menuManagement_MenuManagementNav__F6Ybj .menuManagement_MenuManagementNavList__v2fF4 > section:hover{
    
    background-color: rgba(1, 1, 1,0.5) !important;
    transition: 0.2s;
}


.menuManagement_MenuManagementNav__F6Ybj .menuManagement_MenuManagementNavList__v2fF4 > section >a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    width: 150px;
    height: 100%;
    
}
.menuManagement_MenuManagementNav__F6Ybj .menuManagement_MenuManagementNavList__v2fF4 > section >a:hover{
    color: white;
    transition: 0.2s;

}

`, "",{"version":3,"sources":["webpack://./src/static/css/HostMenu/menuManagement.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,YAAY;;;AAGhB;AACA;IACI,kBAAkB;IAClB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;;;AAG3B;AACA;;IAEI,8CAA8C;IAC9C,gBAAgB;AACpB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;IACZ,YAAY;IACZ,YAAY;;AAEhB;AACA;IACI,YAAY;IACZ,gBAAgB;;AAEpB","sourcesContent":[".MenuManagementNav{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n.flexR{\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n}\n\n\n.MenuManagementNav .MenuManagementNavList{\n    height: 40px;\n    margin-bottom: 10px;\n    display: flex;\n    width: 450px;\n    \n    \n}\n.MenuManagementNav .MenuManagementNavList > section{\n    margin-right: 15px;\n    display: flex;\n    justify-items: center;\n    align-items: center;\n    justify-content: center;\n    align-items: center;\n    padding: 0px !important;\n    \n    \n}\n.MenuManagementNav .MenuManagementNavList > section:hover{\n    \n    background-color: rgba(1, 1, 1,0.5) !important;\n    transition: 0.2s;\n}\n\n\n.MenuManagementNav .MenuManagementNavList > section >a{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-decoration: none;\n    color: black;\n    width: 150px;\n    height: 100%;\n    \n}\n.MenuManagementNav .MenuManagementNavList > section >a:hover{\n    color: white;\n    transition: 0.2s;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuManagementNav": `menuManagement_MenuManagementNav__F6Ybj`,
	"flexR": `menuManagement_flexR__2pEGi`,
	"MenuManagementNavList": `menuManagement_MenuManagementNavList__v2fF4`
};
export default ___CSS_LOADER_EXPORT___;

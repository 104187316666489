// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login_loginArea__XRGjm{
    display: flex;
    padding: 15px;

    flex-direction: column;

    background-color: white;
    border-radius: 7px;
    background-clip: padding-box;

    border:3px solid rgba(1, 1, 1, .05);
    padding: 50px;
    width:25%;

}

#login_loginArea__XRGjm >form >div{
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/static/css/login.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;;IAEb,sBAAsB;;IAEtB,uBAAuB;IACvB,kBAAkB;IAClB,4BAA4B;;IAE5B,mCAAmC;IACnC,aAAa;IACb,SAAS;;AAEb;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["#loginArea{\n    display: flex;\n    padding: 15px;\n\n    flex-direction: column;\n\n    background-color: white;\n    border-radius: 7px;\n    background-clip: padding-box;\n\n    border:3px solid rgba(1, 1, 1, .05);\n    padding: 50px;\n    width:25%;\n\n}\n\n#loginArea >form >div{\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginArea": `login_loginArea__XRGjm`
};
export default ___CSS_LOADER_EXPORT___;

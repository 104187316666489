
import React, { useState, createContext, useContext } from "react";
import { ApplicationContext } from '../context/ApplicationContext'

export const HostContext = createContext();


export function HostContextProvider(props) {

    const applicationContext = useContext(ApplicationContext)

    const initialItems = null;

    const [webSocket,setWebSocket] = useState(initialItems);

    const [loginUser,setLoginUser]= useState(null);

    const [table,setTable]=useState([
        [
            {
                "num": 210,
                "photo": "",
                "kategorieDto": null,
                "name": "테스트",
                "price": 12000,
                "comment": null,
                "user": null,
                "count": 1
            }
        ],
    ])

    const [orderHistory,setOrderHistory] = useState([])


    

    return (
        <HostContext.Provider value={{webSocket,setWebSocket,loginUser,setLoginUser,table,setTable,orderHistory,setOrderHistory}}>
            {props.children}
        </HostContext.Provider>
    )
}

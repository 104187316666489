import './header.css'
import './footer.css'
import './main.css'
import { useContext } from 'react'
import { ClientContext } from '../../../Page/ClientMenu/Client'

export function Header(){

    return(

        <header>
            <i className='xi-info-o'></i>
            <i className='xi-home-o'></i>

        </header>
    )
}

export function Footer(){

    return(

        <footer>
            <h1>메인 푸터</h1>
        </footer>
    )
}

/**
 * 
 * @param mainContent 속성 
 * @returns 
 */
export function Layout(props){



    return(

        <>
            <main>
                {props.mainContent}
            </main>
        </>

    )

}
import axios from "axios";

export function TestPage(){
    
    const joinPs = ()=>{

        let formData =document.getElementById('joinForm')

        axios.post('http://192.168.0.2:8080/user/join',formData)
            .then(function(response){

                console.log(response.data.data)

            })
            .catch(function(err){

                console.log(err.response.data)
                
            })
    
        
    }

    return(

        <>
            <h1>테스트 페이지</h1>

            <form id="joinForm" >

                <dl>
                    <dt>
                        아이디
                    </dt>
                    <dd>
                        <input name="memId" ></input>
                    </dd>

                </dl>

                <dl>
                    <dt>
                        비밀번호
                    </dt>
                    <dd>
                        <input type='password' name="pwd" ></input>
                    </dd>

                </dl>


                <dl>
                    <dt>
                        이름
                    </dt>
                    <dd>
                        <input  name="name" ></input>
                    </dd>

                </dl>


                <dl>
                    <dt>
                        이메일
                    </dt>
                    <dd>
                        <input type="email" name="email" ></input>
                    </dd>

                </dl>

                <dl>
                    <dt>
                        전화번호
                    </dt>
                    <dd>
                        <input name="mobile"  type='number' ></input>
                    </dd>

                </dl>

                <dl>
                    <dt>
                        주소
                    </dt>
                    <dd>
                        <input name="address" ></input>
                    </dd>

                </dl>
                

            </form>


            <button type="button" onClick={()=>{
                joinPs()
            }}  > 요청 보내기 </button>

        </>
    )
}
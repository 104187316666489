import axios from 'axios'
import React, { useEffect,useContext, useState } from 'react';
import { ApplicationContext } from '../../../context/ApplicationContext'
import menuAddStyle from '../../../static/css/HostMenu/MenuManagementTab/MenuAdd.module.css'


export function MenuAdd() {

    const applicationContext = useContext(ApplicationContext)

    const [kategorieList, setKategorieList] = new useState([])


    useEffect(function () {


        //카테고리 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/kategorieSetting/gets`)
            .then(function (response) { //카테고리 데이터 처리
                const result = response.data
                //정상 응답시 처리
                if (result.isSuccess) {
                    setKategorieList([...result.data])


                } else {

                }

            }).catch(function (err) {
                alert(err)
            })
    }, [])





    const newMenu = {
        picture: '',
        name: ' ',
        kategorie: '',
        price: '',
        comment: ''
    }





    const [menu, setMenu] = new useState(newMenu);


    const encodeFileToBase64 = (fileBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve) => {
            reader.onload = () => {

                setMenu({
                    ...menu,
                    picture: reader.result
                })

                resolve();
            };
        });
    };

    function previewImg(e) {

        if (e.target.files[0].type.indexOf('image') !== -1) {

            encodeFileToBase64(e.target.files[0]);
        } else {
            e.target.value = ''
            setMenu({
                ...menu,
                picture: ''
            })

            alert('이미지 파일만 선택 해주세요')
        }

    }


    function addMenuInputEv(e) {

        let name = e.target.name
        let value = e.target.value;

        setMenu({
            ...menu,
            [name]: value

        })


    }

    function AddMenuPs(e) {


        let formData = e.target.parentElement.parentElement.parentElement

        let validErrEls=document.getElementsByClassName('validErr')

        let resultSc=document.getElementById('resultSc')

        console.log(validErrEls)

        resultSc.className='dn'

        for(let i=0;i<validErrEls.length;i++){
            validErrEls[i].innerHTML=""
        }

        

        console.log(formData)

        axios({
            method: 'post',
            url: `${applicationContext.kioskServer}/host/MenuManagement/add`,
            responseType: 'json',
            data: formData,
            withCredentials: true

        }).then(function (response) {
            let responseData=response.data

            if(responseData.isSuccess){

                console.log(responseData.data)
                resultSc.className=''
                document.getElementsByName('file')[0].value=null;
                setMenu({...newMenu})


            }else{
                console.log(responseData.data)

                if(responseData.message="비어있는 값이 존재하는지 확인해주세요"){
                    for(let fieldErr of responseData.data){
                        let errEl=document.getElementById(`${fieldErr.field}Err`)
                        errEl.innerHTML=fieldErr.defaultMessage
                        errEl.classList.remove('dn')
                        
                    }
                }
                

            }

        }).catch(function (error) {
            console.log(error)
        })


    }



    return (
        <div className={menuAddStyle.flexR} >

            <section  >

                <div>
                    <h2 onClick={()=>{console.log(kategorieList)}} >미리보기</h2>

                </div>

                <p id={menuAddStyle.previewPicture} >
                    <img src={menu.picture} alt='이미지를 선택해주세요'  ></img>
                </p>

                <p id={menuAddStyle.previewKategorie} >

                    {menu.kategorie}
                </p>

                <p id={menuAddStyle.previewName} >
                    {menu.name}
                </p>

                <p id={menuAddStyle.previewPrice} >
                    {menu.price}
                </p>

                <p id={menuAddStyle.previewComment} >
                    {menu.comment}
                </p>


            </section>

            <section  >
                <div>
                    <h2>메뉴 추가</h2>

                </div>

                <form>
                    <span id='resultSc' className='dn'  style={{color:'green'}} >메뉴 추가완료</span>
                    <dl>
                        <dt>사진 <span id='photoErr' className='validErr' style={{color:'red',fontSize:'11px'}} ></span></dt>
                        <dd>
                            <input name='file' className="form-control" type='file' onChange={previewImg} ></input>
                            <input name='photo' hidden readOnly value={menu.picture} ></input>

                        </dd>
                    </dl>
                    <dl>
                        <dt>카테고리</dt>
                        <dd>
                            <select name='kategorie'  class="form-select" aria-label="Default select example">
                               
                             {
                                kategorieList.map(t=>{
                                    return(
                                        <option value={t.num} >
                                            {t.name}
                                        </option>
                                    )

                                })

                             }
                               
                            </select>
                        </dd>
                    </dl>

                    <dl>
                        <dt>이름 <span id='nameErr' className='validErr' style={{color:'red',fontSize:'11px'}} ></span></dt>
                        <dd>
                            <input name='name' value={menu.name} className="form-control" type='text' onChange={addMenuInputEv} ></input>
                            
                        </dd>
                    </dl>
                    <dl>
                        <dt>가격 <span id='priceErr' className='validErr'  style={{color:'red',fontSize:'11px'}} ></span></dt>
                        <dd>
                            <input name='price' value={menu.price} className="form-control" type='number' onChange={addMenuInputEv}  ></input>
                            

                        </dd>
                    </dl>
                    <dl>
                        <dt>설명 <span id='commentErr' className='validErr' style={{color:'red',fontSize:'11px'}}  ></span></dt>
                        <dd>
                            <textarea name='comment' value={menu.comment} className="form-control" onChange={addMenuInputEv}   ></textarea>
                            

                        </dd>
                    </dl>
                    <dl>
                        <dd>
                            <button onClick={AddMenuPs} className="w-100 btn btn-primary btn-lg" type="button">메뉴 추가</button>

                        </dd>
                    </dl>

                </form>

            </section>

        </div>
    )
}
import './popup.css'


/**
 * 
 * 
 * props.id
 * props.className
 * props.width
 * props.heigth
 * 
 * @param {*} 
 * @returns 
 */
export function Popup(props){

    const css=[
        {   //백그라운드
            position:'absolute',
            top:'0%',
            left:'0%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%',
            backgroundColor:'rgba(1,1,1,0.2)',
            transition:'0.2s'

        },
        
    ]



    return(
        
        <div id={`${props.id}_background`} className={props.className} style={css[0]} >


            <div id={`${props.id}_window`} 

                style={{

                    backgroundColor:'white',
                    padding:'50px',
                    marginBottom:'0px',
                    display:'flex',
                    height:`${props.height}`,
                    width:`${props.width}`,
                    transition:'0.2s'


                }} >
                    {props.children}

            </div>

        </div>    
            
            
        

    )

}
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {Layout} from '../../Component/layout/MainLayout/MainLayout'
import { ApplicationContext } from '../../context/ApplicationContext'
import Styles from '../../static/css/ClientMenu/tableSetting.module.css'
import { ClientContext } from './Client'

export function TableSettingPage(){


    const applicationContext=useContext(ApplicationContext)

    const clientContext= useContext(ClientContext)

    
    const [inputNum,setInputNum]=useState(0)
    

    useEffect(()=>{
        
        if(clientContext.loginUser==null){
            axios({
                method: 'post',
                url: `${applicationContext.kioskServer}/login/loginCk`,
                responseType: 'json',
                withCredentials:true
              })
            .then(function(response){
                const result = response.data

                if(result.data!=null){
                    clientContext.setLoginUser(result.data)
                }

            })
            .catch(function(err){
                
            })

        }

    },[inputNum,clientContext.loginUser])

    // if(clientContext.loginUser===null){

    //     return <LoginPage />;
    // }else{
    // }



    const webSocket = clientContext.getWebSocket
    const ws = webSocket()

    const connect =()=>{
        console.log('접속하기 클릭')


        const sendObject={

            action:'TABLEACCESS',
            requestUser:clientContext.loginUser,
            data:{
                tableNum: clientContext.tableNum

            }
        }

        let sendData=JSON.stringify(sendObject)
        
        ws.send(sendData)

        console.log(sendObject)
        
        setTimeout(()=>{
            clientContext.setTableNum(inputNum)

        },1000)


    }


    const Content={


        mainContent:
            <section className={Styles.TableSettingSection} >
            
                <h1 onClick={()=>{
                    console.log(clientContext.loginUser)
                }} >테이블번호를 입력하세요</h1>

                <div id={Styles.inputForm} >
                    <input value={inputNum} onChange={(e)=>{
                        setInputNum(e.currentTarget.value)
                        console.log(inputNum)
                    }}  />
                    <div className={Styles.btnGroup} >
                        <button onClick={()=>{
                            setInputNum(inputNum+1)

                        }} ><i className='xi-angle-up' ></i></button>
                        <button onClick={()=>{
                            setInputNum(inputNum-1)
                        }} ><i className='xi-angle-down' ></i></button>
                    </div>
                    
                </div>
                <Link   to='/client/' >
                    <button 
                    
                    onClick={()=>{
                        connect()
                        
                    }} 
                    
                    
                    type="button"   
                    className="w-100 btn btn-primary">접속하기</button>
                </Link>

                
            
            </section>

    }


    return(
        <Layout mainContent={Content.mainContent} />

    )
}
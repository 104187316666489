// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "EUC-KR";

html {font-size: 13px;
height: 100%;}
body { height: 100%; margin: 0;}
a {text-decoration: none;}
ul, ol {list-style: none; margin: 0; padding: 0;}
dl, dt, dd {margin: 0; padding: 0;}


input[type="text"], 
input[type="password"], 
input[type="email"], 
input[type="number"] { border: 1px solid #d5d5d5; height: 35px; padding: 0 10px; border-radius: 20px;}
main{ min-height: 100%; padding-top:0px;}
.dn{ display: none !important; }
button{border:none; background-color: rgba(255,255,255,0);}

/** 여백*/
.mr{ margin-right: 50px;}
.ml{ margin-left: 50px;}
.mt10{ margin-top: 10px; }
.mt20{ margin-top: 20px; }
.mt100{ margin-top: 100px; }
.mb10{ margin-bottom: 10px; }
.mb20{ margin-bottom: 20px; }
/** 여백*/


#root{
  height: 100%;
}


#TOSSPAY_POP_IFRAME{
  height: 100% !important ;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB,MAAM,eAAe;AACrB,YAAY,CAAC;AACb,OAAO,YAAY,EAAE,SAAS,CAAC;AAC/B,GAAG,qBAAqB,CAAC;AACzB,QAAQ,gBAAgB,EAAE,SAAS,EAAE,UAAU,CAAC;AAChD,YAAY,SAAS,EAAE,UAAU,CAAC;;;AAGlC;;;uBAGuB,yBAAyB,EAAE,YAAY,EAAE,eAAe,EAAE,mBAAmB,CAAC;AACrG,MAAM,gBAAgB,EAAE,eAAe,CAAC;AACxC,KAAK,wBAAwB,EAAE;AAC/B,OAAO,WAAW,EAAE,qCAAqC,CAAC;;AAE1D,OAAO;AACP,KAAK,kBAAkB,CAAC;AACxB,KAAK,iBAAiB,CAAC;AACvB,OAAO,gBAAgB,EAAE;AACzB,OAAO,gBAAgB,EAAE;AACzB,QAAQ,iBAAiB,EAAE;AAC3B,OAAO,mBAAmB,EAAE;AAC5B,OAAO,mBAAmB,EAAE;AAC5B,OAAO;;;AAGP;EACE,YAAY;AACd;;;AAGA;EACE,wBAAwB;AAC1B","sourcesContent":["@charset \"EUC-KR\";\n\nhtml {font-size: 13px;\nheight: 100%;}\nbody { height: 100%; margin: 0;}\na {text-decoration: none;}\nul, ol {list-style: none; margin: 0; padding: 0;}\ndl, dt, dd {margin: 0; padding: 0;}\n\n\ninput[type=\"text\"], \ninput[type=\"password\"], \ninput[type=\"email\"], \ninput[type=\"number\"] { border: 1px solid #d5d5d5; height: 35px; padding: 0 10px; border-radius: 20px;}\nmain{ min-height: 100%; padding-top:0px;}\n.dn{ display: none !important; }\nbutton{border:none; background-color: rgba(255,255,255,0);}\n\n/** 여백*/\n.mr{ margin-right: 50px;}\n.ml{ margin-left: 50px;}\n.mt10{ margin-top: 10px; }\n.mt20{ margin-top: 20px; }\n.mt100{ margin-top: 100px; }\n.mb10{ margin-bottom: 10px; }\n.mb20{ margin-bottom: 20px; }\n/** 여백*/\n\n\n#root{\n  height: 100%;\n}\n\n\n#TOSSPAY_POP_IFRAME{\n  height: 100% !important ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tableSetting_inputForm__tjxX\\+{
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 25px;
}




#tableSetting_inputForm__tjxX\\+ input{
    width: 100px;
    height: 100px;
    border: 1px solid rgb(209, 209, 209);
    text-align: center;
    font-size: 45px;
    border-radius: 4px 0px 0px 4px;
}

#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 {
    display: flex;
    flex-direction: column;

}


#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 button {
    flex-grow: 1;
    display: flex;
    padding: 0px;
    
}

#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 button i{
    flex-grow: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    
}
#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 button:nth-child(1)  {
    border-top: 1px solid rgb(209, 209, 209) ;
    border-right: 1px solid rgb(209, 209, 209);
}
#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 button:nth-child(2)  {
    border-right: 1px solid rgb(209, 209, 209);
    border-bottom: 1px solid rgb(209, 209, 209);
}


#tableSetting_inputForm__tjxX\\+ .tableSetting_btnGroup__36uU9 button  :hover {

    background-color: gray;

}





.tableSetting_TableSettingSection__JWe13{
    flex-direction: column;

}`, "",{"version":3,"sources":["webpack://./src/static/css/ClientMenu/tableSetting.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;;;;AAKA;IACI,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,kBAAkB;IAClB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;;AAE1B;;;AAGA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;;AAEhB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;;AAEvB;AACA;IACI,yCAAyC;IACzC,0CAA0C;AAC9C;AACA;IACI,0CAA0C;IAC1C,2CAA2C;AAC/C;;;AAGA;;IAEI,sBAAsB;;AAE1B;;;;;;AAMA;IACI,sBAAsB;;AAE1B","sourcesContent":["#inputForm{\n    display: flex;\n    justify-content: center;\n    margin-top: 25px;\n    margin-bottom: 25px;\n}\n\n\n\n\n#inputForm input{\n    width: 100px;\n    height: 100px;\n    border: 1px solid rgb(209, 209, 209);\n    text-align: center;\n    font-size: 45px;\n    border-radius: 4px 0px 0px 4px;\n}\n\n#inputForm .btnGroup {\n    display: flex;\n    flex-direction: column;\n\n}\n\n\n#inputForm .btnGroup button {\n    flex-grow: 1;\n    display: flex;\n    padding: 0px;\n    \n}\n\n#inputForm .btnGroup button i{\n    flex-grow: 1;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-left: 10px;\n    padding-right: 10px;\n    \n}\n#inputForm .btnGroup button:nth-child(1)  {\n    border-top: 1px solid rgb(209, 209, 209) ;\n    border-right: 1px solid rgb(209, 209, 209);\n}\n#inputForm .btnGroup button:nth-child(2)  {\n    border-right: 1px solid rgb(209, 209, 209);\n    border-bottom: 1px solid rgb(209, 209, 209);\n}\n\n\n#inputForm .btnGroup button  :hover {\n\n    background-color: gray;\n\n}\n\n\n\n\n\n.TableSettingSection{\n    flex-direction: column;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputForm": `tableSetting_inputForm__tjxX+`,
	"btnGroup": `tableSetting_btnGroup__36uU9`,
	"TableSettingSection": `tableSetting_TableSettingSection__JWe13`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main_mainMenu__N0zEK{
    width: 80%;
    display: flex;
    justify-content: space-around;
}

#main_mainMenu__N0zEK > a > i{

    display: flex;
    justify-content: center;
    align-items: center;

    color: white;
    font-size: 90px;
    

}

#main_mainMenu__N0zEK > a {
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: rgba(1, 1, 1, 0.2);

    border-radius: 50%;

    text-decoration: none;

    width: 250px;
    height: 250px;

}

#main_mainMenu__N0zEK > a >span{
    color: white;
    margin-left: 15px;
    font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/static/css/main.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,6BAA6B;AACjC;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;IACZ,eAAe;;;AAGnB;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oCAAoC;;IAEpC,kBAAkB;;IAElB,qBAAqB;;IAErB,YAAY;IACZ,aAAa;;AAEjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["#mainMenu{\n    width: 80%;\n    display: flex;\n    justify-content: space-around;\n}\n\n#mainMenu > a > i{\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    color: white;\n    font-size: 90px;\n    \n\n}\n\n#mainMenu > a {\n    \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 30px;\n    background-color: rgba(1, 1, 1, 0.2);\n\n    border-radius: 50%;\n\n    text-decoration: none;\n\n    width: 250px;\n    height: 250px;\n\n}\n\n#mainMenu > a >span{\n    color: white;\n    margin-left: 15px;\n    font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenu": `main_mainMenu__N0zEK`
};
export default ___CSS_LOADER_EXPORT___;

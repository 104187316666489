import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { ApplicationContext } from "../../context/ApplicationContext"
import { HostContext } from "../../context/HostContext"
import Styles from '../../static/css/HostMenu/OrderManagement.module.css'

export function OrderManagement() {
    

    const applicationContext=useContext(ApplicationContext)

    const hostContext = useContext(HostContext)

    const [loadDataSetting,setLoadDataSetting]=useState(1)

    useEffect(()=>{
        console.log('주문 관리 리로드')

    },[hostContext.orderHistory])


    function moreHistory(){

        console.log('지금',hostContext.orderHistory)

        console.log('더불러오기')
        
        let formData=new FormData();

        formData.append('startDay',loadDataSetting)
        formData.append('offsetDay',1)
        
        
        axios.post(`${applicationContext.kioskServer}/host/order/history`,formData)
            .then(function(response){

                let responseData=response.data

                if(responseData.isSuccess){
                    console.log(responseData.data)
                    hostContext.setOrderHistory([...hostContext.orderHistory,
                        {

                            type:`information`,
                            data: `${loadDataSetting}일 전`

                        }
                        
                        ,...responseData.data])
                    setLoadDataSetting(loadDataSetting+1)
                    console.log('지금',hostContext.orderHistory)


                }else{

                }


            })
            .catch(function(err){
                alert(err)
            })
            console.log('지금',hostContext.orderHistory)

    }



    return (
        <section className={Styles.OrderHistoryArea} >

            <h1 onClick={()=>{
                console.log(hostContext.orderHistory)
            }} >주문 관리</h1>

            <div id={Styles.OrderHistoryList} >
                    <dl className={Styles.orderHistoryInfo} >
                            <dt>
                                테이블
                            </dt>
                        <dd>
                            <p>
                            기준 시간 {new Intl.DateTimeFormat('kr').format(new Date())}
                            </p>
                                    
                        </dd>
                    </dl>
                {
                    hostContext.orderHistory.map((order,index)=>{

                        if(order.type==='information'){
                            return(
                                <dl key={index} className={Styles.orderHistoryInfo}>
                                    <dt>
                                        {order.data} 
                                    </dt>
                                </dl>

                            )
                        }


                        let sep=<></>
                        if(index%5===0){
                            sep=<hr style={{border:'2px solid black'}} ></hr>
                        }



                        if(order.menu==null){
                            return (
                                <>
                            <dl key={index} >
                                <dt>
                                    {order.tableStateDto ? order.tableStateDto.tableNum:'완료'}   
                                </dt>
                                <dd>
                                    <p>
                                        <span>
                                            삭제된 메뉴 <i className="xi-close-thin"></i> {order.count}
                                        </span>
                                        <span>
                                            {`${order.modDt.substr(order.modDt.indexOf('T')+1,5)}`}
                                        </span>
                                    </p>
                                    
                                </dd>
                            </dl>
                            {sep}
                            </>
                           
                           
                           
                           )
                        }else{

                            return (
                                <>
                            <dl key={index} >
                                <dt>
                                    {order.tableStateDto ? order.tableStateDto.tableNum:'완료'}   
                                </dt>
                                <dd>
                                    <p>
                                        <span>
                                            {order.menu.name} <i className="xi-close-thin"></i> {order.count}
                                        </span>
                                        <span>
                                            {`${order.modDt.substr(order.modDt.indexOf('T')+1,5)}`}
                                        </span>
                                    </p>
                                    
                                </dd>
                            </dl>
                            {sep}
                            </>
                           
                           
                           
                           )
                        }
                           
                        })
                        
                    }
                    
                    <button  id={Styles.moreHistoryBtn}
                    onClick={()=>{
                        moreHistory();
                    }}
                >더보기</button>
            </div>

        </section>
    )

}
import { useEffect, useState, useContext } from 'react'
import { ApplicationContext } from '../../context/ApplicationContext'
import { Layout } from '../../Component/layout/MainLayout/MainLayout'
import clientCss from '../../static/css/ClientMenu/order.module.css'
import { TableSettingPage } from '../ClientMenu/TableSetting'
import { LoginPage } from '../Login'
import { Popup } from '../../Component/layout/Popup'




import axios from 'axios'
import { ClientContext } from './Client'
import { useParams } from 'react-router-dom'

export function OrderPage() {

    const tablePath = useParams().tablePath


    const wsContext = useContext(ClientContext)

    const clientContext = useContext(ClientContext)

    const ws = wsContext.getWebSocket()


    //정적 변수 불러오기 (백엔드 서버 주소)
    const applicationContext = useContext(ApplicationContext)

    //카테고리 데이터 
    const [kategorieList, setKategorieList] = new useState([])

    const [menuList, setMenuList] = new useState([])

    const [orderCart, setOrderCart] = [clientContext.orderCart, clientContext.setOrderCart]

    const [popup, setPopup] = new useState('dn')

    // 토스

    // const clientKey = 'test_ck_ADpexMgkW36PDm5OyXMrGbR5ozO0'

    // test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq



    //


    // 페이지 로딩 또는 카테고리 수정 시 서버에서 재로딩
    useEffect(function () {

        console.log('리로드')

        //카테고리 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/kategorieSetting/gets`)
            .then(function (response) { //카테고리 데이터 처리
                const result = response.data
                //정상 응답시 처리
                if (result.isSuccess) {
                    console.log('카태고리 데이터', result.data)
                    setKategorieList([...result.data])

                } else {

                }

            }).catch(function (err) {
                alert(err)
            })

        //메뉴 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/gets`)
            .then(function (response) {

                const result = response.data

                if (result.isSuccess) {
                    console.log('메뉴 데이터', result.data)
                    setMenuList([...result.data])
                } else {

                }


            })
            .catch(function (err) {

            })


    }, [clientContext.tableNum, clientContext.loginUser,applicationContext.kioskServer, setKategorieList, setMenuList])


    // 로그인 확인
    if (clientContext.loginUser == null) {

        return <LoginPage />;
    } else {
    }



    if (tablePath !== 0 && tablePath !== undefined) {
        console.log(tablePath)
        clientContext.setTableNum(tablePath)
    }

    //테이블 세팅
    if (clientContext.tableNum === 0) {
        return <TableSettingPage />
    }


    const addCart = (e) => {
        let target = e.currentTarget
        let menuData = target.dataset
        // let tableNum = clientContext.tableNum

        let already = orderCart.reduce((acc, cur, index, element) => {

            if(cur.num===menuData.num){
                cur.count++
                setOrderCart([
                    ...orderCart
                ])

            }

            return (
                cur.num===menuData.num || acc
            )
        }, false)

        console.log(already)

        if (already) {

        } else {

            setOrderCart([
                ...orderCart,
                {
                    photo: menuData.photo,
                    num: menuData.num,
                    name: menuData.name,
                    price: menuData.price,
                    count: 1

                }

            ])

        }

      



    }



    const orderPs=()=>{
        console.log(1)

        let copyData=JSON.stringify(orderCart)

        copyData=JSON.parse(copyData);

        copyData={
            action: 'ORDER',
            requestUser: clientContext.loginUser,
            data: {
                        tableNum: clientContext.tableNum,
                        menuData: copyData.map((t)=> {
                            delete t.photo
                            return t

                        } ),
                },
        }

        const data = JSON.stringify(copyData)

        console.log(data)

        let formData=new FormData()
        formData.append('user',copyData.requestUser.num)
        formData.append('tableNum',copyData.data.tableNum)
        formData.append('orderData',JSON.stringify(copyData.data.menuData))

        axios.post(`${applicationContext.kioskServer}/host/order/ps`,formData)
            .then(function(response){

                let responseData=response.data

                if(responseData.isSuccess){

                    console.log(responseData)

                    setOrderCart([])

                }else{
                    alert('주문 실패')
                    alert(responseData.message)
                }

            })
            .catch(function(err){

                alert(err)

            })

        console.log(data)

        ws.send(data)
        
        setPopup('dn')
    }


    const findByKategorie = (e) => {


        const selectKategorie = e.currentTarget.dataset.kategorie

        console.log(selectKategorie)

        const formData = new FormData()

        if (selectKategorie) {
            formData.append('kategorie', selectKategorie)

        }


        //메뉴 데이터 요청
        axios.post(`${applicationContext.kioskServer}/host/MenuManagement/gets`, formData)
            .then(function (response) {

                const result = response.data

                if (result.isSuccess) {
                    setMenuList([...result.data])
                } else {

                }


            })
            .catch(function (err) {

            })


    }

    const paymentPs = () => {


        setPopup('')


    }



    const Content = {

        mainContent:
            <div className={clientCss.clientSectionOuter} >

                <section id={clientCss.menuKategorie} >
                    <h3>No.{clientContext.tableNum}</h3>
                    <ul id={clientCss.kategoryUl} >

                        <li onClick={(e) => { findByKategorie(e) }} >
                            <p>모든 카테고리</p>
                        </li>

                        {
                            kategorieList.map((data, index) => {
                                return (

                                    <li data-kategorie={data.num} onClick={(e) => {
                                        findByKategorie(e)
                                    }} key={index} >
                                        <p>{data.name}</p>
                                    </li>


                                )
                            })
                        }


                    </ul>
                </section>
                <section id={clientCss.menuList} >

                    {menuList.map((t, index) => {

                        return (

                            <dl key={index}
                                data-name={t.name}
                                data-num={t.num}
                                data-price={t.price}
                                data-photo={t.photo}

                                onClick={(e) => {

                                    addCart(e)

                                }}  >
                                <dt>
                                    <img src={t.photo} alt='메뉴사진' ></img>
                                </dt>

                                <dd>
                                    <p>
                                        {t.name}
                                    </p>

                                    <p>
                                        {t.price} 원
                                    </p>

                                </dd>


                            </dl>

                        )

                    })}


                </section>
                <section id={clientCss.selectMenu} >

                    <div id={clientCss.orderCartArea} >

                        {
                            orderCart.map((t, index) => {
                                return (
                                    <>
                                        <dl key={index} >
                                            <dt>
                                                <img alt='' src={t.photo}></img>
                                            </dt>
                                            <dd>
                                                <p>
                                                    {t.name}
                                                </p>
                                                <p>
                                                    {t.price}
                                                </p>
                                                <p>
                                                    <button onClick={() => {

                                                        t.count = t.count + 1
                                                        setOrderCart([
                                                            ...orderCart
                                                        ])
                                                    }} className='xi-plus' ></button>
                                                    <input min={1} value={t.count} readOnly   ></input>
                                                    <button onClick={() => {
                                                        if (t.count !== 1) {
                                                            t.count = t.count - 1
                                                            setOrderCart([
                                                                ...orderCart
                                                            ])

                                                        } else {
                                                            orderCart.shift(index)
                                                            setOrderCart([
                                                                ...orderCart
                                                            ])
                                                        }
                                                    }} className='xi-minus' ></button>
                                                </p>
                                            </dd>

                                        </dl>
                                    </>
                                )
                            })
                        }

                    </div>
                    <div id={clientCss.paymentArea} >
                        <p id={clientCss.orderCartPrice} >

                            {

                                orderCart.reduce((acc, cur, index, element) => {
                                    return (
                                        acc + (Number)(cur.price * cur.count)
                                    )
                                }
                                    , 0)

                            } 원

                        </p>
                        <button onClick={() => {

                            paymentPs()

                        }} id={clientCss.paymentBtn} >주문하기</button>
                    </div>
                </section>
                <Popup className={popup} >
                    <div id={clientCss.confirmArea} >
                        <h1>주문하시겠습니까?</h1>
                        <div className={clientCss.btngroup} >
                            <button onClick={()=>{orderPs()}}  > 확인 </button>
                            <button onClick={() => {
                                setPopup('dn')
                            }} > 취소 </button>
                        </div>
                    </div>
                </Popup>
            </div>

    }




    return (

        <Layout mainContent={Content.mainContent} >

        </Layout>

    )

}